import React, { Component } from "react";
import ReactDOM from "react-dom";
import "../../../App.css";
import Logo from '../../images/logo/indoconnex-logo.png';
import WebBannerImages from'../../images/company-landing/home-effect/bg.jpg';
import MainScreen from'../../images/company-landing/home-laptop.png';
import MainEffect from'../../images/company-landing/home-effect/1.png';
import MainEffect2 from'../../images/company-landing/home-effect/2.png';
import MainEffect3 from'../../images/company-landing/home-effect/3.png';


class WebBanner extends Component {
    render() {
        return (
            <section className="home-section overflow-hidden">
                {/* <img src={WebBannerImages} alt="logo" className="img-fluid  lazyload bg-img"/> */}
                <div className="home-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-lg-2">
                                <div className="content-screen">
                                    <div className="main-screen">
                                    <img src={MainScreen} alt=""
                                            className="img-fluid blur-up lazyload user wow zoomIn" data-wow-delay="0.60s"/>
                                    </div>
                                    <div className="home-effect"></div>
                                    <img src={MainEffect} alt="logo" className="img-fluid wow zoomIn"/>
                                    <img src={MainEffect2} alt="logo" className="img-fluid wow zoomIn"/>
                                    <img src={MainEffect3} alt="logo" className="img-fluid wow zoomIn"/>

                                </div>
                            </div>
                            <div className="col-lg-5 order-lg-1">
                                <div className="content">
                                <div>
                                    <h1>Welcome to <span>IndoConnex</span></h1>
                                        <b>Connecting Indonesia to the World</b>
                                        <p>An integrated online platform that connects Indonesia to the world and the world to Indonesia,
                                        making everyday needs accessible from one unifield source.
                                        </p>
                                        <a href="#" className="btn btn-solid btn-lg">get started</a>
                                </div>
                                </div>
                            </div>
                            <div className="animation-home">
                                <div className="cross"></div>
                                <div className="cross cross1"></div>
                                <div className="tringle"></div>
                                <div className="tringle tringle1"></div>
                                <div className="circle"></div>
                                <div className="circle circle1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
      }
    }
    
    export default WebBanner;


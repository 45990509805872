import Home from './pages/Home';
import About from './modules/user/profile/componens/about';
import Contact from './modules/user/profile/componens/contact';
import Error from './pages/Error';
import Dashboard from './modules/user/profile/componens/dashboard'
import Profile from './modules/user/profile/componens/profile'
import Register from './modules/user/auth/register/componens/RegisterUser';
import RegisterCOnfirm from './modules/user/auth/register/componens/RegisterConfirm';
import Navbar from './modules/public/navigation/navbar';
import WebBanner from './modules/user/web_banner/web_banner';
import Login from './modules/user/auth/login/componens/LoginUser';

const routes = [
  {
    name: "pages",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "home",
        collapse: [
          {
            name: "about us",
            route: "/",
            component: <Home />,
          },
          {
            name: "register",
            route: "register",
            component: <Register />,
          },
          {
            name: "login",
            route: "login",
            component: <Login />,
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    icon: "",
    collapse: [
      {
        name: "getting started",
        description: "All about overview, quick start, license and contents",
        href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-kit/",
      },
      {
        name: "foundation",
        description: "See our colors, icons and typography",
        href: "https://www.creative-tim.com/learning-lab/react/colors/material-kit/",
      },
      {
        name: "components",
        description: "Explore our collection of fully designed components",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
      },
      {
        name: "plugins",
        description: "Check how you can integrate our plugins",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
      },
    ],
  },
  {
    name: "github",
    icon: "",
    href: "https://www.github.com/creativetimofficial/material-kit-react",
  },
];

export default routes;

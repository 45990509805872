import React, {useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import GoogleIcon from '../../../../images/icon/Google_Icons.webp';
import Select from 'react-select'

const RegisterForm = () => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("")
    const [email, setEmail] = useState('');
    const history = useLocation();
    const [msg, setMsg] = useState('');

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/locations/countries`).then((response) => {
            setCountries(response.data);
        });
      }, []);

    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/register`, {
                email: email,
                country: country
            }).then((response) => {
                setMsg(response.data);
            })
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    return (
        <div className="col-xl-6 col-lg-7 col-md-10 col-12 m-auto">
            <div className="login-form">
                <div>
                    <div className="login-title">
                        <h2>Register</h2>
                    </div>
                    <div className="login-discription">
                        <h3>Hello Everyone</h3>
                        <h4>Welcome to Indoconnex, create your account below to start usinf Indoconnex.</h4>
                    </div>
                    <div className="form-sec">
                    <div>
                        <form onSubmit={Register} className="theme-form">
                        <p className="text-danger">{msg}</p>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1"
                                    placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                                <i className="input-icon iw-20 ih-20" data-feather="mail"></i>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Country</label>
                                {/* <select className="form-control">
                                {countries.map(country => (
                                    <option value={country.id} onChange={(e) => setEmail(e.target.value.id)}>{country.name}</option>
                                ))}
                                </select> */}
                                <Select
                                    onChange={(country) => setCountry(country.name)}
                                    options={countries}
                                    formatOptionLabel={country => (
                                        <div className="country-option">
                                            <img src={country.image} alt="country-image" style={{height: "20px", width: "30px", marginRight: "5px"}} />
                                            <span>{country.name}</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="bottom-sec">
                                <div className="form-check checkbox_animated">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">remember me</label>
                                </div>
                                <a href="#" className="ms-auto">forget password?</a>
                                </div>
                            <div className="btn-section">
                                <button className="btn btn-solid btn-lg">sign up</button>
                                <a href="login" className="btn btn-solid btn-lg ms-auto">login</a>
                            </div>
                        </form>
                        <div className="connect-with">
                            <h6><span>OR Connect With</span></h6>
                            <ul className="social-links">
                                <li className="google">
                                   <img src={GoogleIcon} className="icon-login" />          
                                </li>
                                <li className="facebook">
                                   
                                </li>
                                <li className="twitter">
                                   
                                </li>
                            </ul>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default RegisterForm;
import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import About from './modules/user/profile/componens/about';
import Contact from './modules/user/profile/componens/contact';
import Error from './pages/Error';
import Dashboard from './modules/user/profile/componens/dashboard'
import Profile from './modules/user/profile/componens/profile'
import Register from './modules/user/auth/register/componens/RegisterUser';
import RegisterCOnfirm from './modules/user/auth/register/componens/RegisterConfirm';
import Navbar from './modules/public/navigation/navbar';
import WebBanner from './modules/user/web_banner/web_banner';
import Login from './modules/user/auth/login/componens/LoginUser';
import routes from './routes';

import {
  BrowserRouter,
  HashRouter,
  NavLink,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import RegisterConfirm from './modules/user/auth/register/componens/RegisterConfirm';

function App() {
  let activeClassName = 'nav-active';

  let [loggedIn, setLoggedIn] = useState(null);
  function handleLogin(){
    setLoggedIn(true);
  }
  function handleLogout(){
    setLoggedIn(false);
  }

  const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });
  return (
    <div>
    <BrowserRouter>
      <header className="App-header">
        <Navbar />
      </header>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
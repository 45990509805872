import React, {useState} from "react";
import axios from "axios";
import {useLocation, NavLink} from "react-router-dom";
import GoogleIcon from '../../../../images/icon/Google_Icons.webp';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const history = useLocation();
    const [msg, setMsg] = useState('');
    const [password, setPassword] = useState('');
    
    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, {
                email: email,
                password: password
            }).then((response) => {
                setMsg(response.data.message);
            })
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    return (
        <div className="col-xl-6 col-lg-7 col-md-10 col-12 m-auto">
            <div className="login-form">
                <div>
                    <div className="login-title">
                        <h2>Login</h2>
                    </div>
                    <div className="login-discription">
                        <h3>Hello Everyone</h3>
                        <h4>Welcome to Indoconnex, create your account below to start usinf Indoconnex.</h4>
                    </div>
                    <div className="form-sec">
                    <div>
                        <form onSubmit={Register} className="theme-form">
                        <div className="text-center">
                            <h2 className="text-danger">{msg}</h2>
                        </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1"
                                    placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                                <i className="input-icon iw-20 ih-20" data-feather="mail"></i>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1"
                                    placeholder="*****" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <i className="input-icon iw-20 ih-20" data-feather="mail"></i>
                            </div>
                            <div className="bottom-sec">
                                <div className="form-check checkbox_animated">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">remember me</label>
                                </div>
                                <a href="#" className="ms-auto">forget password?</a>
                                </div>
                            <div className="btn-section">
                                <button className="btn btn-solid btn-lg">Login</button>
                                <NavLink to='/register' className="btn btn-solid btn-lg ms-auto">
                                    Signup
                                </NavLink>
                            </div>
                        </form>
                        <div className="connect-with">
                            <h6><span>OR Connect With</span></h6>
                            <ul className="social-links">
                                <li className="google">
                                   <img src={GoogleIcon} className="icon-login" />          
                                </li>
                                <li className="facebook">
                                   
                                </li>
                                <li className="twitter">
                                   
                                </li>
                            </ul>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default LoginForm;
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "../../../App.css";
import Logo from '../../images/logo/indoconnex-logo.png';

import {
    NavLink,
  } from 'react-router-dom';
  
let activeclassName = 'nav-active';
const NavBar  = () => {
    return (
      <header className="no-sticky bg-transparent home-landing overflow-hidden">
        <div className="container">
            <div className="header-section">
                <div className="header-left">
                    <div className="logo-sec col-4">
                        <NavLink to='/' className="nav-link">
                            <img src={Logo} alt="logo"
                                className="img-fluid lazyload" />
                        </NavLink>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="navbar navbar-expand-lg pe-0">
                        <div className="overlay-bg"></div>
                        <button className="navbar-toggler p-0" type="button">
                            <i data-feather="menu" className="icon iw-22 ih-22 icon-light"></i>
                        </button>
                        <div className="navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                {/* <li className="nav-item d-block d-lg-none back-btn">
                                    <a className="nav-link" href="/">back</a>
                                </li> */}
                                <li className="nav-item active">
                                    <NavLink to='/' className="nav-link">
                                        Home
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="about-us.html">features</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="career.html">testimonial</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="blog.html">download</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="faq.html">contact</a>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to='/login' className="nav-link d-flex align-items-center btn btn-white">
                                        <i className="me-2 iw-18 ih-18" data-feather="log-in"></i>login
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
      );
    }

export default NavBar;
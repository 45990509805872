import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../../images/icon/indoconnex-logo.png';
import Character from '../../../../images/login/charcter.png';
import LoginForm from './LoginForm';

function Login(){
  let navigate = useNavigate();
  return (
    <section className="login-section mt-4">
        <div className="container mt-4">
            <div className="row">
                <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                    <div className="login-welcome">
                        <div>
                            <img src={Character} className="img-fluid lazyload"
                                alt="charcter" />
                            <h1 className='text-dark'>Welcome friend!.</h1>
                        </div>
                    </div>
                </div>
                    <LoginForm />
            </div>
        </div>
        <div className="how-work">
            <div className="media">
                <i data-feather="play-circle"></i>
                <div className="media-body">
                    <h2 className='text-dark'>how it work?</h2>
                    <p className='text-dark'>
                        Friendbook is a website which allows users, who sign-up for free profiles, to connect with
                        friends, work colleagues or people they don't know, online.
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Login;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import bgImage from '../modules/images/company-landing/home-bg.jpg';
import homeLaptop from '../modules/images/company-landing/home-laptop.png';
import WebBanner from '../modules/user/web_banner/web_banner';
import WidgetImages from '../modules/images/businesspage/business1.png';
import CoonectingBusiness from '../../src/modules/images/company-landing/feature1.png';
import ConnectingOpportunities from '../../src/modules/images/company-landing/feature2.png';
import ConnectingPeople from '../../src/modules/images/company-landing/feature3.png';
import ConnectingKindness from '../../src/modules/images/company-landing/feature4.png';




function Home({ login }) {
  let navigate = useNavigate();
  return (
    <div>
    <WebBanner />

    <section className="section-pt-space sm-pb-space">
    <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="title-sec">
                        <h4>our features</h4>
                        <h3>we provide listing from business to community</h3>
                    </div>
                </div>
                <div className="filter-section section-b-space">
                    <div className="card-title">
                        <div className="ms-auto button-group filters-button-group">
                        <button className="button is-checked" data-filter="*">show all</button>
                            <button className="button mt-2" data-filter=".businessdirectory">Business</button>
                            <button className="button mt-2" data-filter=".jobs">Jobs</button>
                            <button className="button mt-2" data-filter=".lifestyle">Lifestyle</button>
                            <button className="button mt-2" data-filter=".personalcare">Personal Care</button>
                            <button className="button mt-2" data-filter=".travel_leisure">Travel & Leisure</button>
                            <button className="button mt-2" data-filter=".health">Health</button>
                            <button className="button mt-2" data-filter=".publiresources">Public Resources</button>
                            <button className="button mt-2" data-filter=".properties">Properties</button>
                            <button className="button mt-2" data-filter=".telecommunication">Telecommunication</button>
                            <button className="button mt-2" data-filter=".commnunity&charity">Community & Charity</button>
                        </div>
                    </div>
                    <div className="filter-content">
                         <div className="grid row  mb-2">
                            {/* Business Categories Start */}
                            <h3 className="pb-2">Business</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/* Jobs Categories Start */}
                            <h3 className="pb-2">Jobs</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/* Lifestyle Categories Start */}
                            <h3 className="pb-2">Lifestyle</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/* Personal Care Categories Start */}
                            <h3 className="pb-2">Personal Care</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/* Travel & Leisure Categories Start */}
                            <h3 className="pb-2">Travel & Leisure</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/*List of Properties
 Categories Start */}
                            <h3 className="pb-2">List of Properties</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/*Public Resources Categories Start */}
                            <h3 className="pb-2">Public Resources</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/*Telecommunication Categories Start */}
                            <h3 className="pb-2">Telecommunication</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/*Telecommunication Categories Start */}
                            <h3 className="pb-2">Community & Charity</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Community & Charity</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid row mb-2">
                            {/*Telecommunication Categories Start */}
                            <h3 className="pb-2">Food & Beverages</h3>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Food & Beverages</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                            <div className="element-item simulation" data-category="simulation">
                                <div className="game-box">
                                <div className="img-sec bg-size">
                                <img src={WidgetImages} alt="" className="img-fluid lazyload" />
                                <a href="#"><h4>Business Directory</h4></a>
                                </div>
                                <div className="game-content">
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </section>

    <section className="feature-section section-pt-space sm-pb-space overflow-hidden">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="title-sec">
                        <h4>our features</h4>
                        <h3>Why IndoConnex</h3>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mobile-w100">
                    <div className="feature-box">
                        <div className="icon-box">
                        <img src={CoonectingBusiness} alt="logo" className="img-fluid blur-up lazyloaded" />
                        </div>
                        <div className="feature-content">
                            <h3>Connecting Businesses</h3>
                            <p>Facilitate trade & investment, boost business exposure, and build or enhance existing relations between Indonesia’s businesses and all countries in the world
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mobile-w100">
                    <div className="feature-box">
                        <div className="icon-box">
                        <img src={ConnectingOpportunities} alt="logo" className="img-fluid blur-up lazyloaded" />
                        </div>
                        <div className="feature-content">
                            <h3>Connecting Opportunities</h3>
                            <p>Providing a channel that offers limitless opportunities in various aspects such as employment, economy, education, tourism, culture, and so forth.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mobile-w100">
                    <div className="feature-box">
                        <div className="icon-box">
                        <img src={ConnectingPeople} alt="logo" className="img-fluid blur-up lazyloaded" />
                        </div>
                        <div className="feature-content">
                            <h3>Connecting People                            </h3>
                            <p>Building a better life and empowering communities by creating a real, tangible connection through trade and culture
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mobile-w100">
                    <div className="feature-box">
                        <div className="icon-box">
                            {/* <img src="assets/images/company-landing/icon/feature4.png"
                                className="img-fluid blur-up lazyload" alt=""> */}
                        </div>
                        <div className="feature-content">
                            <h3>Connecting Kindness
                            </h3>
                            <p>Enabling everyone to create a positive impact daily, by providing a safe and secure platform to give back to people in need through donations or charity projects
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="animation-home">
            <div className="cross"></div>
            <div className="cross cross1"></div>
            <div className="tringle"></div>
            <div className="tringle tringle1"></div>
            <div className="circle"></div>
        </div>
    </section>
    </div>

  );
}

export default Home;